/* global dataLayer, RECAPTCHA_SITE_KEY_V3, FACEBOOK_APP_ID */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import fscreen from 'fscreen'
import Helmet from 'react-helmet'
import { calculateScrollPercentage } from 'scroll-percentage'
import LoadingBar from 'react-redux-loading-bar'
import { Toaster } from 'react-hot-toast'

import mergeProps from 'reduxModules/app/dispatchers'
import stateSelector from 'reduxModules/app/selectors'

import logger from '../utils/logger'
import parsePathname from '../utils/parsePathname'

import VerifyBanner from './VerifyBanner'
import MobileSideBar from './MobileSideBar'
import NavBar from './NavBar'
import Footer from './Footer'
import SubscriptionBanner from './SubscriptionBanner'
import SimpleFooter from './SimpleFooter'
import Confirmation from './Confirmation'

import './styles.css'

export function App(props) {
  const [showingLoadingBar, setShowingLoadingBar] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0)
    props.globalPromo(props.location?.query?.codename)
  }, [props.location?.pathname])
  useEffect(() => {
    if (props.loading === 1 && !showingLoadingBar) {
      setShowingLoadingBar(true)
      props.showLoadingBar()
    } else if (props.loading === 0 && showingLoadingBar) {
      props.hideLoadingBar()
      setShowingLoadingBar(false)
    }
  }, [props.loading, showingLoadingBar])
  // Screen resize listener
  useEffect(() => {
    const handleResize = () => {
      props.updateDimensions(window.innerWidth, window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [props.isMobile])
  // Fetch authentication data
  useEffect(() => {
    const { loadUserInfo, location, storeUTM, isAuthenticated } = props
    if (isAuthenticated) {
      loadUserInfo()
      dataLayer.push({ isLoggedIn: true })
    } else props.proxyLogin(props)

    storeUTM(location.query)
    props.loadCartItems()
    props.loadCurrentPromotion()
    props.globalPromo(location?.query?.codename)
  }, [props.isAuthenticated])
  // Instantiate scrolling and full screen listeners
  useEffect(() => {
    const handleFullscreenChange = () => {
      props.updateFullscreenStatus(fscreen.fullscreenElement !== null)
    }

    const handleScroll = _.debounce(e => {
      try {
        const childElemHeight = document.querySelector('.page-body')
          .clientHeight
        const scrollPercentage = calculateScrollPercentage(
          e.target,
          childElemHeight
        )
        if (typeof scrollPercentage === 'number')
          props.updateScrollPercentage(scrollPercentage)
      } catch (error) {
        logger.error(`Error while detecting scroll height: ${error}`)
      }
    }, 250)

    props.updateDimensions(window.innerWidth, window.innerHeight)

    if (fscreen.fullscreenEnabled)
      fscreen.addEventListener(
        'fullscreenchange',
        handleFullscreenChange,
        false
      )

    window.addEventListener('scroll', handleScroll)

    return () => {
      if (fscreen.fullscreenEnabled)
        fscreen.removeEventListener('fullscreenchange', handleFullscreenChange)

      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const { isMobile } = props
  const hasMobileSubsCTA = !props.isSubscriber && props.width < 870
  const userIsVerified = props.isAuthenticated && props.user.isVerified
  const useSimpleFooter = () => {
    const simplifiedPaths = [
      '/customer-care',
      '/checkout',
      '/review_order',
      '/contest'
    ]
    const path = props.location.pathname
      .split('/')
      .slice(0, 2)
      .join('/')
    return simplifiedPaths.indexOf(path) >= 0
  }
  const isContest = () => {
    const { location } = props
    return location.pathname && location.pathname.startsWith('/contest')
  }
  const isSubscriptionPage = () => {
    const { location } = props
    return (
      location.pathname && location.pathname.startsWith('/subscriptions/plans')
    )
  }
  return (
    <div id="App">
      <Helmet>
        <title>Stan Winston School of Character Arts</title>
        <meta content="Stan Winston School" property="og:site_name" />
        <meta content={parsePathname(window.location.href)} property="og:url" />
        <meta content={FACEBOOK_APP_ID} property="fb:app_id" />
        <meta content="3 days" name="revisit-after" />
        <link
          rel="canonical"
          href={window.location.href.split('?')[0].replace(/\/$/, '')}
        />
      </Helmet>
      {props.isAuthenticated &&
      !userIsVerified &&
      props.location.pathname !== '/checkout' ? (
        <VerifyBanner displace={props.showMobileMenu && isMobile} />
      ) : null}
      {isMobile ? (
        <div
          className={`mobile-menu ${
            props.showMobileMenu && isMobile ? 'displace' : ''
          }`}
        >
          <MobileSideBar pathName={props.location.pathname} />
        </div>
      ) : null}
      {props.showMobileMenu && isMobile && (
        <button
          type="button"
          aria-label="Close mobile sidebar by clicking outside of it"
          className="cloak"
          onClick={
            props.showMobileMenu && isMobile
              ? () => props.toggleMobileMenu()
              : null
          }
        />
      )}
      <div
        className={`page-body ${
          props.showMobileMenu && isMobile ? 'displace ' : ''
        }${
          (!props.showBannerSubscription || isMobile) && !props.hideNav
            ? 'without-subs-banner '
            : ''
        }
        ${
          !props.hideNav &&
          (!props.isAuthenticated ||
            props.user.isVerified ||
            props.location.pathname === '/checkout')
            ? 'without-verified '
            : ''
        }
        ${
          props.hideNav &&
          (!props.isAuthenticated ||
            props.user.isVerified ||
            props.location.pathname === '/checkout')
            ? 'without-verified-and-nav '
            : ''
        }
        ${
          !props.hideNav &&
          props.showBannerSubscription &&
          !props.showMobileMenu &&
          !isMobile &&
          !isSubscriptionPage()
            ? 'with-nav '
            : ''
        }
        ${useSimpleFooter() ? 'enabled-simple-footer ' : ''}
        ${isContest() ? 'is-contest' : ''}`}
        style={{ height: !props.hideNav ? 'auto' : props.height }}
      >
        <LoadingBar className="loading" />
        {!props.hideNav && !isContest() && (
          <NavBar pathName={props.location.pathname} />
        )}
        {props.showBannerSubscription &&
          !props.hideNav &&
          !props.isFullscreen &&
          !props.usedFreeTrial &&
          !isContest() &&
          !isSubscriptionPage() && (
            <SubscriptionBanner
              onClose={props.closeBannerSubscription}
              isMobile={props.isMobile}
              userIsVerified={userIsVerified}
              userIsAuthenticated={props.isAuthenticated}
            />
          )}
        <div
          className={`content ${
            hasMobileSubsCTA ? 'with-mobile-subscribe-cta ' : ''
          }${
            props.showCookieNotice && props.width < 870
              ? 'with-cookie-notice'
              : ''
          }`}
        >
          {props.children}
        </div>
        {!props.hideNav && !useSimpleFooter() && !isContest() && (
          <Footer isMobile={isMobile} />
        )}
        {!props.hideNav && useSimpleFooter() && !isContest() && (
          <SimpleFooter />
        )}
        {props.confirmation.active && (
          <Confirmation
            {...props.confirmation}
            subscription={props?.subscription}
          />
        )}
        <Toaster
          toastOptions={{
            success: {
              duration: 5000
            },
            error: {
              duration: 8000
            },
            style: {
              padding: '8px',
              maxWidth: '375px'
            },
            ariaProps: {
              role: 'status',
              'aria-live': 'polite'
            }
          }}
        />
      </div>
    </div>
  )
}

App.propTypes = {
  children: PropTypes.element,
  isAuthenticated: PropTypes.bool.isRequired,
  globalNotification: PropTypes.shape({
    message: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired
  }),
  showBannerSubscription: PropTypes.bool,
  showCookieNotice: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      category: PropTypes.string
    }),
    pathname: PropTypes.string
  }),
  confirmation: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    title: PropTypes.string,
    text: PropTypes.string,
    accept: PropTypes.string,
    reject: PropTypes.string
  }).isRequired,

  // Dispatchers
  clearGlobalNotification: PropTypes.func.isRequired,
  loadUserInfo: PropTypes.func.isRequired,
  showLoadingBar: PropTypes.func.isRequired,
  hideLoadingBar: PropTypes.func.isRequired,
  closeBannerSubscription: PropTypes.func.isRequired,
  updateDimensions: PropTypes.func.isRequired,
  loadCartItems: PropTypes.func.isRequired,
  updateFullscreenStatus: PropTypes.func.isRequired,
  proxyLogin: PropTypes.func.isRequired,
  globalPromo: PropTypes.func.isRequired
}

App.defaultProps = {
  children: null,
  globalNotification: null,
  showBannerSubscription: true,
  location: {
    query: {}
  }
}

export default connect(stateSelector, null, mergeProps)(App)
